import React, { Component } from 'react'
import {Button,Divider,Modal,Form,Input,Radio,Select} from 'antd'
import Navbar from './Navbar'
import './CSS/ShippingProfile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes,faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'
import ProfileSidebar from './ProfileSidebar'
import { url,headers } from "../Constants";
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const {Option} = Select
class ShippingProfile extends Component {
    constructor(props){
        super(props)
        this.initialState={
            isAddShippment:false,
            shippingCheckBoxValue:'Domestic',
            listData:[],
            domCost:'0',
       domDelivery:{
         from:'0',
         to:'0'
       },
       domesticService:"",
       intCost:'0',
       intDelivery:{
         from:'0',
         to:'0'
       },
       internationalService:"",
       domAdditional:'0',
       intAddtional:'0',
       loading:false,
       type:'both',
       showDomestic:false,
       showInternational:false,
       showBoth:true,
       title:'',
       description:'',
       showDomOther:false,
       otherDomService:'',
       showIntService:false,
       otherIntSevice:'',
       show:true,
       shippingProfile:null,
       showUpdateModal:false
        }
        this.state={
            ...this.initialState
        }
        this.handleChange=this.handleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.handleDelete=this.handleDelete.bind(this)
        this.showUpdateModalFunc=this.showUpdateModalFunc.bind(this)
    }
    handleChange(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    handleOpenModal=()=>{
        this.setState({isAddShippment:true})
    }
    handleCloseModal=()=>{
        this.setState({isAddShippment:false})
    }
    handleCloseUpdateModal=()=>{
        this.setState({showUpdateModal:false})
    }
    handleChangeShipping=(e)=>{
        this.setState({shippingCheckBoxValue:e.target.value})
    }
    componentDidMount(){
        let userData = localStorage.getItem('userData')
        let user = JSON.parse(userData)
        let UID = user.firebaseUID
        if(UID!==""){
            fetch(url+'/api/getShippings'+UID)
            .then(res=>res.json())
            .then(response=>{
                if(response.message==='Success'){
                    console.log(response.doc)
                    this.setState({
                        listData:response.doc
                    })
                }
                else{
                    alert("Failed to fetch shipping profiles")
                }
            })
        }
    }
    handleSubmit(e){
        e.preventDefault()
        if(this.state.shippingCheckBoxValue==='Domestic'){
                let {domCost,domesticService,domDelivery,title,description,otherDomService} = this.state
                let data = {
                    domCost,
                    domesticService:domesticService==='Other'?otherDomService:domesticService,
                    title,
                    description,
                    domDelivery,
                    type:this.state.shippingCheckBoxValue,
                    firebaseUID:this.props.UID
                }
                console.log(data)
                if(title.length<4){
                    alert('Title must be minimum 4 characters')
                    return
                }
                if(description.length<4){
                    alert('Description must be minimum 4 characters')
                    return
                }
                if(domesticService.length<4){
                    alert('User must select shipping profile')
                    return
                }
                if(domDelivery.from.length<1 || domDelivery.to.length<1){
                    alert('Delivery time can not be less than 1 day')
                    return
                }
                fetch(url+'/api/addShipping',{method:"POST",body:JSON.stringify(data),headers:headers})
                .then(res=>res.json())
                .then(response=>{
                    if(response.message==='Success'){
                        let obj= this.initialState
                        delete obj.listData
                        this.setState({
                            ...obj
                        })
                        let updatedShipping = this.state.listData
                        updatedShipping.push(response.doc)
                        this.setState({
                            listData:updatedShipping
                        })
                    }else{
                        alert("Adding Profile failed")
                    }
                }).catch(err=>console.log(err))

        }
        else if(this.state.shippingCheckBoxValue==='International'){
            let {intCost,internationalService,intDelivery,title,description,otherIntSevice} = this.state
            let data = {
                intCost,
                internationalService:internationalService==='Other'?otherIntSevice:internationalService,
                title,
                description,
                intDelivery,
                type:this.state.shippingCheckBoxValue,
                firebaseUID:this.props.UID
            }
            console.log(data)
            if(title.length<4){
                alert('Title must be minimum 4 characters')
                return
            }
            if(description.length<4){
                alert('Description must be minimum 4 characters')
                return
            }
            if(internationalService.length<4){
                alert('User must select shipping profile')
                return
            }
            if(intDelivery.from.length<1 || intDelivery.to.length<1){
                alert('Delivery time can not be less than 1 day')
                return
            }
            fetch(url+'/api/addShipping',{method:"POST",body:JSON.stringify(data),headers:headers})
            .then(res=>res.json())
            .then(response=>{
                if(response.message==='Success'){
                    let obj= this.initialState
                    delete obj.listData
                    this.setState({
                        ...obj
                    })
                    let updatedShipping = this.state.listData
                    updatedShipping.push(response.doc)
                    this.setState({
                        listData:updatedShipping
                    })
                }else{
                    alert("Adding Profile failed")
                }
            }).catch(err=>console.log(err))
        }
        else{
            let {domCost,domesticService,domDelivery,title,description,otherDomService,intCost,internationalService,intDelivery,otherIntSevice} = this.state
            let data = {
                domCost,
                domesticService:domesticService==='Other'?otherDomService:domesticService,
                title,
                description,
                domDelivery,
                type:this.state.shippingCheckBoxValue,
                firebaseUID:this.props.UID,
                intCost,
                internationalService:internationalService==='Other'?otherIntSevice:internationalService,
                intDelivery
            }
            console.log(data)
            if(title.length<4){
                alert('Title must be minimum 4 characters')
                return
            }
            if(description.length<4){
                alert('Description must be minimum 4 characters')
                return
            }
            if(domesticService.length<4){
                alert('User must select shipping profile')
                return
            }
            if(domDelivery.from.length<1 || domDelivery.to.length<1){
                alert('Delivery time can not be less than 1 day')
                return
            }
            fetch(url+'/api/addShipping',{method:"POST",body:JSON.stringify(data),headers:headers})
            .then(res=>res.json())
            .then(response=>{
                if(response.message==='Success'){
                    let obj= this.initialState
                    delete obj.listData
                    this.setState({
                        ...obj
                    })
                    let updatedShipping = this.state.listData
                    updatedShipping.push(response.doc)
                    this.setState({
                        listData:updatedShipping
                    })
                }else{
                    alert("Adding Profile failed")
                }
            }).catch(err=>console.log(err))
        }
    }
    showUpdateModalFunc(index){

    }
    handleDelete(id){
        if(id){
            confirmAlert({
                title: 'Confirm Delete?',
                message: 'Are you sure to delete shipping profile?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
            fetch(url+'/api/deleteShipping'+id,{method:"DELETE",headers:headers})
            .then(res=>res.json())
            .then(response=>{
                if(response.message==='Success'){
                    let updatedShipping = this.state.listData.filter(list=>list._id!==id)
                    this.setState({
                        listData:updatedShipping
                    })
                }else{
                    alert('Failed to delete shipping profile')
                }
            })
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {

                    }
                  }
                ]
              });
        }
    }
    render() {
        return (
            <div className="Shipping-container">
                <Navbar />

                <Button shape="circle" style={{backgroundColor:'#8b0000',color:'white',width:'85px',height:'85px',fontSize:'30px',position:'absolute',bottom:30,right:30}} onClick={()=>{
                    this.props.history.push('/shipping-profile-form')
                }} >+</Button>
                <div className="Shipping-body">
                    <ProfileSidebar />
                   <div className="Shipping-body-lists">
                    <h2 style={{textAlign:"center"}}> Perfiles de envío</h2>
                       {
                          this.state.listData.length>0 && this.state.listData.map((item)=>{
                               return(
                                <div className="Shipping-list">
                                    <div style={{display:'flex',flexDirection:"column"}}>
                                        <h3 style={{padding:0,margin:0}}>{item.title}</h3>
                                        <p lines='2' style={{color:'gray'}}>{item.description.substring(0,100)}</p>
                                    </div>
                                    <div style={{display:'flex'}}>
                                        {/* <FontAwesomeIcon icon={faEdit} size="lg" /> */}
                                        {/* <p style={{marginLeft:'5px',marginRight:'5px'}}>|</p> */}
                                        <FontAwesomeIcon onClick={()=>this.handleDelete(item._id)} icon={faTrash} size="lg" />
                                    </div>
                                </div>
                               )
                           })  
                        }
                   </div>
                </div>


            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        categories:state.rootReducer.categories,
        UID:state.rootReducer.UID,
        query:state.rootReducer.query,
        data:state.rootReducer.data
    })
}
function mapActionsToProps(dispatch) {
    return ({
        
    })
}
export default connect(mapStateToProps,mapActionsToProps)(ShippingProfile)