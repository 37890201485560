import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import privacyImage from './privacy_policy.jpg'
import './CSS/Privacy.css'

class HelpCenter extends Component {
   componentDidMount(){
    window.scrollTo(0, 0)

   }
    render() {
        return (
            <div className="Privacy-Container">
                <Navbar />
                <h1 style={{textAlign:"center",color:"white",position:"absolute",marginLeft:"43%",marginTop:"10%",fontWeight:"bolder"}}>PRIVACY POLICY</h1>

                <div className="Privacy-Body-Container">
                    <div style={{maxWidth:'100%',maxHeight:300,backgroundColor:'#8b0000',position:'flxed'}}>
                        <img alt='privacy not found' src={privacyImage} style={{width:'100%',height:300,opacity:0.2}} />
                    </div>
                    <div className="Privacy-Body">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    <p>Última actualización: 14 de mayo de 2019 <br/>
                    Esta declaración de política de privacidad es creada por My Consignment (myconsignment.co), Mi
Consignación (miconsignación.com, miconsignacion.com), Pure Artisan (pureartisan.co), Pure
Artesanal (puroartesanal.com), Alberto Juan (www.albertojuandesigns.com), Treasure Trove
NYC (treasuretrovenyc.com) y etsy.tube (en adelante, "Proveedor" o "nosotros") respetan su
privacidad y se compromete a ayudarlo a comprender qué tipo de datos recopilamos, por qué recopilamos
y lo que hacemos con él. Tómese el tiempo de leer detenidamente la política de privacidad. Proporcionando
nosotros con información personal, significa que está de acuerdo con esta Política de privacidad.</p>
                <h1>1. Aspectos bajo la tutela de esta política.</h1>
                <p>Actualmente trabajamos con las aplicaciones móviles del Proveedor (la "Aplicación"), así como con
los sitios web My Consignment (myconsignment.co), Mi Consignación (miconsignación.com,
miconsignacion.com), Pure Artisan (pureartisan.co), Pure Artesanal (puroartesanal.com), Alberto
Juan (www.albertojuandesigns.com), Treasure Trove NYC (treasuretrovenyc.com) y
etsy.tube (el "Sitio web" y, junto con la aplicación, los "Servicios"). Esta política de privacidad
cubre todos los aspectos de los servicios.
De acuerdo con la normativa de la Ley Orgánica 15/99, de 13 de diciembre, de Protección de la Persona
Datos, se le informa que todos los datos personales proporcionados a través de las aplicaciones y los sitios web
www.miconsignacion.com, www.miconsignación.com, www.puroartesanal.com y
www.albertojuandesigns.com se incluirá en un archivo propiedad de Treasure Trove NYC.</p>
                <h1>2. ¿Qué información personal recopilamos de usted?</h1>
                <p>2.1. Necesitamos recopilar datos personales de nuestros proveedores a través de los Servicios indicados en este
Política. Esta información puede incluir su nombre, dirección de correo electrónico, dirección postal, fecha de nacimiento,
género y fotos. También necesitamos recibir sus datos personales de otras fuentes, como
bases de datos públicas, socios de marketing, plataformas de redes sociales (incluida la lista de sus
amigos u otras personas con las que está vinculado) y otros terceros.
Puede usar nuestra aplicación y visitar nuestro sitio web sin proporcionar su información personal.
Tenemos cuidado de recopilar sus datos personales para proporcionar ciertos servicios. Si te niegas a
proporcionar su información personal cuando sea necesario, es posible que la aplicación no pueda proporcionar
ciertos servicios (por ejemplo, marcando su cuenta como "verificada"). Puedes elegir
comprar ciertas funcionalidades adicionales, en Proveedor, en relación con los Servicios. Si tu haces eso,
requerimos que use información personal para proporcionar servicios relacionados con esa compra. Por
enviándonos a nosotros oa nuestros proveedores de servicios datos personales sobre otros en relación con el
Servicios, usted declara que tiene la autoridad para hacerlo y que nos permite usar el
información de acuerdo con esta política de privacidad.
2.2. Es necesario recopilar la ubicación física del dispositivo para grabar y publicar
información sobre su posición y mostrarle los productos ofrecidos por los usuarios cercanos a usted. Proveedor
puede recopilar esta información, por ejemplo, con señales de satélite, torre de telefonía celular o Wi-Fi. usted
puede permitir o denegar dicha recopilación y publicación de la ubicación de su dispositivo, pero puede afectar
su uso de los Servicios (por ejemplo, haciendo que sea imposible para nosotros poner sus productos a la venta
en una determinada ubicación geográfica).
2.3 Se requiere el uso de cualquier anuncio publicado por un Proveedor, incluida la foto cargada
o imagen del producto, así como otra información (incluido el nombre de usuario y la ubicación) asociada
con el anuncio, para nuestros propios fines publicitarios, incluidas las redes sociales, Facebook
anuncios, boletines y anuncios de cualquier medio de comunicación.
2.4. Es posible revelar los datos personales que obtenemos de usted a nuestros afiliados para los fines
descrito en esta Política de privacidad; a nuestros proveedores de servicios que brindan servicios como el sitio web
hosting, análisis de datos, procesamiento de pagos, cumplimiento de pedidos, tecnología de la información y el
provisión de infraestructura relacionada, servicio al cliente, servicio de correo electrónico, auditoría y otros servicios;
y un tercero en caso de reorganización, fusión, venta, empresa conjunta, cesión,
transferencia u otra disposición de todo o parte de nuestro negocio, activos o acciones.
2.5 Requerimos usar o divulgar los datos personales que obtenemos de usted, en el caso de que nosotros
lo considere necesario o apropiado, incluido, entre otros, el uso y divulgación de información personal
datos para los siguientes propósitos: proteger a la persona u organización, para proteger nuestros servicios, derechos
o propiedad, para cumplir con los requisitos legales, para responder a un proceso legal o aplicación legal
solicitudes y para cumplir con solicitudes de otras autoridades públicas y gubernamentales.
2.6 Se requiere agregar datos personales, que no agrego personalmente lo identifica a usted o cualquier otro
usuario de los Servicios. Por ejemplo, necesitamos agregar datos personales para calcular el porcentaje de
nuestros usuarios que tienen un código postal particular.</p>
                    <h1>3. Alto y creación</h1>
                    <p>3.1. Para administrar algunos de nuestros servicios, tendrá que registrarse, ya sea proporcionando su correo electrónico
dirección o su cuenta de red social. También necesitamos recopilar información que incluya su
número de teléfono, fecha de nacimiento y sexo, así como información sobre sus preferencias (como
su método preferido de comunicación) En caso de que decida registrarse en una red social
cuenta, nos autoriza a acceder y utilizar cierta información dependiendo de la configuración de privacidad
has seleccionado en esa red social. Por lo tanto, indicamos algunos ejemplos de datos personales que
recopilamos y usamos información que incluye información básica de la cuenta (por ejemplo, nombre, dirección de correo electrónico,
género, fecha de nacimiento, ciudad actual, foto de perfil, ID de usuario), lista de amigos, etc.) y cualquier otro
Información adicional o actividades que permita que la red social comparta.
3.2. Posiblemente sea necesario proporcionar datos personales como una imagen de perfil, nombre y apellido,
nombre de usuario y dirección de correo electrónico para que se muestren en la aplicación y en el sitio web. Usted puede
visite algunas áreas de los Servicios como invitado, pero es posible que no pueda acceder a todos los contenidos y
características de esas áreas sin registrarse.
3.3. Toda la información solicitada en los formularios de la aplicación que no aparece como opcional
son obligatorios con el fin de gestionar eficazmente la relación con el Proveedor.</p>
            <h1>4. Public Data</h1>
            <p>4.1. Debemos tener en cuenta que cualquier información que publique en el Proveedor será visible
y puede estar disponible para otros usuarios y el público en general. Le instamos a tener mucho cuidado cuando
decidir qué información divulgará a través de los Servicios.
4.2. También debe tener en cuenta que el nombre de usuario y su foto pública estarán disponibles para
al público al participar en algunos servicios, como la publicación de artículos para la venta, por lo que
recomendar discreción y prudencia en el uso de los Servicios. Datos personales enviados por usted o divulgados
usted a otros usuarios puede ser recopilado por otros usuarios de estos servicios y puede resultar en
mensajes no solicitados No somos responsables de proteger la información que usted divulga a
terceros a través de nuestros servicios (por ejemplo, enviar su número de teléfono a otro usuario
a través de los Servicios).</p>
            <h1>5. Boletines y notificaciones push</h1>
            <p>De acuerdo con la ley aplicable, necesitamos comunicarnos con usted y / o enviarle comerciales
comunicaciones a través de comunicaciones electrónicas, como el correo electrónico, para informarle sobre nuestro
productos, servicios, ofertas o cualquier contenido comercial relacionado con la empresa. Si tu no quieres
para recibir correos electrónicos de marketing, siempre puede optar por seguir las instrucciones para cancelar el
suscripción que se incluye en dichos correos electrónicos. Tenga en cuenta que incluso si opta por dejar de recibir
comunicaciones comerciales, puede continuar recibiendo comunicaciones administrativas de
el Proveedor, como confirmaciones de transacciones, notificaciones sobre las actividades de su cuenta (para
ejemplo, confirmaciones de cuenta, cambios de contraseña, etc.) y cualquier otra información importante
anuncios También debemos enviarle notificaciones automáticas si la ha activado. Usted puede
deshabilite las notificaciones push en la configuración de su dispositivo móvil.</p>
            <h1>6. ¿Qué datos recopilamos de usted?</h1>
            <p>6.1. En muchos casos, recopilaremos automáticamente cierta información sobre su uso del
Servicios. Recopilamos esta información para garantizar que los servicios funcionen correctamente. Podemos recoger,
entre otras cosas, información sobre su navegador o dispositivo, datos sobre el uso de aplicaciones,
información a través de cookies, etiquetas de píxeles y otras tecnologías, e información agregada. Esta
la información puede incluir:
• Información sobre el uso de la aplicación, como la fecha y hora de acceso a nuestros servidores.
y qué información y archivos se han descargado de la aplicación en función de su dispositivo
número. También es posible que, en algunas versiones de la aplicación, podamos recopilar información
sobre otras aplicaciones que pueda tener en su dispositivo (pero no sobre el contenido de estas
aplicaciones). También necesitamos recopilar información recopilada automáticamente a través de su navegador
o dispositivo, o mediante la aplicación cuando la descarga y la usa. Necesitamos recoger su
Dirección de control de acceso a medios (MAC), tipo de computadora (Windows o Macintosh), resolución
de pantalla, fabricante y modelo del dispositivo, idioma, tipo, versión y nombre de Internet
navegador y la versión de los servicios (como la aplicación) que está utilizando.
• El sistema operativo o la herramienta que está utilizando, el nombre de dominio de su proveedor de servicios de Internet.
y su "ruta de clics" a través de los Sitios o la aplicación;
• La dirección IP, que debemos utilizar para fines tales como el cálculo de los niveles de uso,
diagnosticar problemas del servidor y administrar los Servicios. También necesitamos concluir su
ubicación aproximada basada en su dirección IP;
6.2. En consecuencia, el Proveedor puede usar cookies y otras tecnologías, como se describe en el
sección siguiente.
6.3. También requerimos que recopile información cuando la proporcione voluntariamente, como su
método preferido de comunicación.
6.4 Necesitamos agregar información personal, que cuando se agrega no lo identifica personalmente o
cualquier otro usuario de los Servicios. Por ejemplo, necesitamos recopilar datos personales para calcular el
porcentaje de nuestros usuarios que tienen un código postal particular. Requerimos que use y divulgue
agregar datos personales para cualquier propósito, excepto cuando se nos solicite hacerlo de otra manera
de acuerdo con la ley aplicable.
6.5. Requerimos que use y divulgue información no personal para cualquier propósito, excepto cuando
estamos obligados a hacerlo de otra manera de acuerdo con la ley aplicable. En algunos casos, necesitamos
combine esta información con datos personales. Por ejemplo, si ha creado una cuenta con
nuestros servicios, la información de su cuenta puede estar relacionada con artículos de su compra (por ejemplo,
Su historial de pedidos). Si lo hacemos, trataremos la información combinada como información personal, como
siempre y cuando permanezca combinado.</p>
                <h1>7. Datos sobre cookies y tecnología relacionada.</h1>
                <p>7.1. El proveedor puede contener "cookies".
Necesitamos usar cookies para administrar las sesiones de nuestros usuarios y almacenar las preferencias, el seguimiento
Información y selección de idioma. Las cookies se pueden utilizar tanto si se registra con nosotros como si utiliza el
Servicios como invitado. Las "cookies" son pequeños archivos de texto transferidos por un servidor web en su disco duro
y luego almacenado en su computadora. Los tipos de información generalmente incluyen la fecha y la hora
visitó, el historial de navegación y sus preferencias. Para más información, consulte nuestras Cookies.
Política.
Requerimos el uso de la información recopilada a través de cookies para promover, resaltar o resaltar
ciertos artículos en relación con el uso de los Servicios. Requerimos que use su respuesta a estos
artículos promocionados o destacados, o consultas de búsqueda y resultados, para personalizar los servicios para usted.
Por lo general, puede configurar su navegador para que no acepte cookies. También puedes ir a
www.miconsignacion.com, www.puroartesanal.com y www.albertojuandesigns.com.
Sin embargo, no permitir el uso de cookies puede limitar su acceso a ciertas funciones del
Sitio web. Por ejemplo, puede tener dificultades para acceder o utilizar ciertas funciones interactivas de
la Web, como el foro de proveedores o comentarios.
7.2. Funciones analíticas
Utilizamos Google Analytics, que utiliza cookies y tecnologías similares para recopilar y analizar
información sobre el uso de servicios e informes sobre actividades y tendencias. Este servicio también puede
Recopilar información sobre el uso de otros sitios web, aplicaciones y recursos en línea. Usted puede
aprenda sobre las prácticas de Google, vaya a https://www.google.com/policies/privacy/partners/ y bloquee
descargando la desactivación del navegador Google Analytics, disponible en https: //
tools.google.com/dlpage/gaoptout</p>
                <h1>8. ¿Cómo puede ingresar, eliminar o modificar la información que ha proporcionado?</h1>
                <p>8.1. Puede ejercer sus derechos de acceso, rectificación, cancelación y oposición en cualquier momento
enviando una solicitud a la siguiente dirección de correo electrónico: treasure.trove.nyc@gmail.com
8.2. Para atender adecuadamente su solicitud, indique claramente qué información personal
usted está escribiendo sobre Para su protección, solo necesitamos responder a las solicitudes de datos personales.
asociado con la dirección de correo electrónico que utiliza para enviar su solicitud, y es posible que tengamos que verificar su
identidad antes de implementar su solicitud. Intentaremos cumplir con su solicitud tan pronto como sea posible.
posible, pero es posible que tengamos que conservar cierta información para cumplir con nuestro mantenimiento de registros y
También puede haber información residual que permanecerá en nuestras bases de datos y otros registros, que
puede no estar disponible o ser eliminado.
8.3 El Proveedor ha implementado todas las medidas de seguridad necesarias para garantizar la seguridad de
Datos personales según el estado actual de la tecnología.</p>
                <h1>9. Nuestro compromiso de proteger los datos personales que hemos recopilado.</h1>
                <p>Siempre estamos mejorando medidas organizativas, técnicas y administrativas razonables para
proteger datos personales dentro de nuestra organización. Sin embargo, no hay portal web o transmisión
Eso es completamente seguro. En consecuencia, el Proveedor no puede garantizar que en caso de no estar autorizado
acceso o piratería, no habrá pérdida de datos u otros incidentes. Su uso de los servicios web.
y la aplicación es bajo su propio riesgo. El proveedor lo insta a tomar medidas para mantener su personal
información segura, memorizando su contraseña o guardándola en un lugar seguro. Si tienes signos
creer que su interacción con nosotros ya no es segura (por ejemplo, si cree que el
la seguridad de su cuenta se ha visto comprometida), notifíquenos de inmediato en la sección "Contáctenos".</p>
        <h1>10. Terceros</h1>
        <p>La política de privacidad no cubre y, por lo tanto, el Proveedor no es responsable de la privacidad,
información u otras prácticas de cualquier tercero, incluido cualquier tercero que opera cualquier sitio
o servicio al que se vinculan los Servicios ofrecidos aquí.
También necesitamos utilizar un servicio de pago de terceros para procesar los pagos realizados a través del
Proveedor. Si desea realizar un pago a través del Proveedor, sus datos personales pueden ser
recogido por dicho tercero y no por nosotros, y estará sujeto a la política de privacidad del tercero
fiesta, en lugar de esta política de privacidad. No tenemos control sobre esto, y no somos responsables de
la recopilación, uso y divulgación de dichos datos personales por parte de este tercero.</p>
        <h1>11. Período de protección de la información.</h1>
        <p>Protegemos sus datos personales durante el tiempo necesario para cumplir con los propósitos descritos en esta política de privacidad, a menos que las regulaciones nos obliguen a un período de conservación más largo.</p>
        <h1>12. Uso del proveedor por menores de edad</h1>
        <p>Los servicios no están dirigidos a personas menores de catorce (14) años de edad, y solicitamos que dicho
los menores no proporcionan datos personales a través de los Servicios indicados aquí.</p>
        <h1>13. Transferencia de sus datos personales</h1>
        <p>El propósito de proporcionar los servicios contratados a través de la Aplicación y / o el Sitio web, Proveedor
puede tratar los datos personales de su propiedad, en cuyo caso, será por el procesador de datos en
de conformidad con lo dispuesto en el artículo 12 de la Ley orgánica 15/99, de 13 de diciembre
protección de datos personales (en adelante, LOPD) y artículos 19 y siguientes del Real Decreto
1720/2007, español, de 21 de diciembre, que desarrolla la LOPD.
Nosotros y nuestros afiliados y proveedores de servicios podemos transferir, almacenar y / o procesar su información personal.
datos fuera de su país de residencia. Estos países pueden tener reglas de protección de datos que son
diferente de los de tu país. Sus datos pueden transferirse a las siguientes empresas.
y países: Mi envío (www.miconsignacion.com, www.miconsignación.com), Puro
Artesanal (www.puroartesanal.com) y Alberto Juan (www.albertojuandesigns.com).
A través de la aceptación de esta política de privacidad, usted acepta dicha transferencia, almacenamiento y / o
procesamiento de información personal</p>
        <h1>14. Notificación de cambios en la política</h1>
        <p>El Proveedor se reserva el derecho de modificar esta Política en cualquier momento, notificando a los usuarios registrados a través de
correo electrónico o solicitud de la existencia de una nueva política y / o la publicación de la nueva política en
los servicios. Todos los cambios a la política entrarán en vigencia al momento de su publicación, y su uso continuo de
cualquier proveedor de servicios después de su publicación constituirá su aceptación y acuerdo de ser
obligado por esos cambios.</p>
    <h1>15. Cómo ponerse en contacto con nosotros</h1>
    <p>Si tiene alguna pregunta, queja o comentario sobre esta Política, contáctenos por correo electrónico
en treasure.trove.nyc@gmail.com. Por favor, no nos envíe información confidencial por correo electrónico, ya que
tales comunicaciones no siempre son seguras.</p>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}



export default HelpCenter;
